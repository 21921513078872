import {get, type Writable, writable} from 'svelte/store';
import SingletonDependency from 'Lib/SingletonDependency';
import {Scenario} from 'Generated/RestClient.g';
import type {Nullable} from 'Lib/Utils/Nullable';

export default class PresentationContext extends SingletonDependency {
    public currentScenarioObservable: Writable<Nullable<Scenario>> = writable(undefined);
    public presentationWritableObservable: Writable<boolean> = writable(false);
    public presentationLoadedObservable: Writable<boolean> = writable(false);
    public instanceErrorObservable: Writable<Nullable<string>> = writable(undefined);
    public passwordNeededObservable: Writable<boolean> = writable(false);
    public passwordErrorObservable: Writable<Nullable<string>> = writable(undefined);
    public showLoadingIndicatorObservable: Writable<boolean> = writable(false);
    public isEmbeddedObservable: Writable<boolean> = writable(false);

    public get currentScenario(): Nullable<Scenario> {
        return get(this.currentScenarioObservable);
    }

    public set currentScenario(scenario: Nullable<Scenario>) {
        this.currentScenarioObservable.set(scenario);
    }

    public get presentationWritable(): boolean {
        return get(this.presentationWritableObservable);
    }

    public set presentationWritable(presentationWritable: boolean) {
        this.presentationWritableObservable.set(presentationWritable);
    }

    public get presentationLoaded(): boolean {
        return get(this.presentationLoadedObservable);
    }

    public set presentationLoaded(presentationLoaded: boolean) {
        this.presentationLoadedObservable.set(presentationLoaded);
    }

    public get instanceError(): Nullable<string> {
        return get(this.instanceErrorObservable);
    }

    public set instanceError(error: Nullable<string>) {
        this.instanceErrorObservable.set(error);
    }

    public set passwordNeeded(val: boolean) {
        this.passwordNeededObservable.set(val);
    }

    public get passwordNeeded() {
        return get(this.passwordNeededObservable);
    }

    public set passwordError(val: Nullable<string>) {
        this.passwordErrorObservable.set(val);
    }

    public get showLoadingIndicator() {
        return get(this.showLoadingIndicatorObservable);
    }

    public set showLoadingIndicator(val) {
        this.showLoadingIndicatorObservable.set(val);
    }

    public get isEmbedded() {
        return get(this.isEmbeddedObservable);
    }

    public set isEmbedded(val: boolean) {
        this.isEmbeddedObservable.set(val);
    }
}
